import React from "react";
import { WebCheckIn } from "../pages/CheckIns";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import { WebCheckOut } from "../pages/CheckOut";

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/webcheckin" element={<WebCheckIn />} />
      <Route path="/webcheckout" element={<WebCheckOut />} />
      <Route path="*" element={<Navigate to="/webcheckin" />} />
    </Switch>
  );
};
