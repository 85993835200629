import BYE from "../../../../assets/bye.svg";
import VACATION from "../../../../assets/vacation.jpg";
import { IReservation } from "../../interfaces";

interface IFinish {
  reservation: IReservation;
}

export const Finish: React.FC<IFinish> = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: window.innerWidth > 768 ? "0 20px" : "0 5px",
        flex: 1,
        alignItems: "center",
        gap: 20,
        paddingTop: 20,
      }}
    >
      <div
        style={{
          fontSize: 22,
          fontWeight: "bold",
          textAlign: "center",
          maxWidth: 220,
        }}
      >
        Check-out realizado com sucesso!
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 20,
        }}
      >
        <div>
          <img src={BYE} width={80} style={{marginTop: -10}}/>
        </div>

        <div>
          <img src={VACATION} width={300} />
        </div>
      </div>

      <div style={{ fontWeight: "bold", marginBottom: 20, fontSize: window.innerWidth > 768 ? "" : 14 }}>
        Esperamos vê-lo novamente a we.housy!
      </div>
    </div>
  );
};
