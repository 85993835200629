import React, { useCallback } from "react";
import { Container, Content } from "./style";
import { useGlobal } from "../../hooks/global";
import { LogoIcon } from "../../components/SvgComponents/Icons/LogoIcon";
import { IconPowered } from "../../components/SvgComponents/Icons/IconPowered";
import { MyCircularProgress } from "../../components/MyCircularProgress";
import { useApi } from "../../hooks/api";
import { useSearchParams } from "react-router-dom";
import { Guest, IReservation } from "./interfaces";
import { Start } from "./steps/start";
import { Finish } from "./steps/finish";
import { ConfirmationStep } from "./steps/confirmation";

export const WebCheckOut: React.FC = () => {
  const [step, setStep] = React.useState<
    "start" | "confirmation-steps" | "finish"
  >("start");

  const [params] = useSearchParams();

  const { api } = useApi();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [controlRender, setControlRender] = React.useState<boolean>(false);

  const { theme, containerGlobalRef, notify } = useGlobal();

  const [reservation, setReservation] = React.useState<IReservation | null>(
    null
  );

  const [passwords, setPasswords] = React.useState<{
    offline_password: string | null;
    online_password: string | null;
  }>({
    offline_password: null,

    online_password: null,
  });

  const handleSave = useCallback(
    async () => {
      setLoading(true);
      try {
        await api.post("/check-out", {
          reservation_stays_id: reservation?.id,
        });

        notify("Check-out realizado com sucesso!", "success");
        setStep("finish");
      } catch (e) {
        notify(
          "Problema ao realizar o check-in! Tente novamente mais tarde",
          "alert"
        );
      }

      setLoading(false);
    },
    [reservation]
  );

  const StartChildren = (
    <Start
      onSave={(reservation) => {
        const correctReservation = reservation as IReservation;
        correctReservation.guests = correctReservation.guests.sort((a, b) =>
          a.primary ? -1 : 1
        );
        setReservation(correctReservation);
        setStep("confirmation-steps");
      }}
      defaultReservationId={params.get("reservation_id") ?? ""}
      defaultCheckInDate={params.get("date") ?? ""}
    />
  );

  return (
    <Container>
      <div style={{ marginTop: 30, marginBottom: 10 }}>
        <LogoIcon theme={theme} />
      </div>
      <Content
        ref={containerGlobalRef}
        style={{
          position: "relative",
          overflow: "hidden",
          display: loading ? "none" : "flex",
        }}
      >
        {step === "start" && !controlRender && StartChildren}
        {step === "start" && controlRender && StartChildren}
        {step === "confirmation-steps" && (
          <ConfirmationStep
            reservation={reservation as any}
            onSave={handleSave}
          />
        )}
        {step === "finish" && (
          <Finish reservation={reservation as IReservation} />
        )}
      </Content>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <MyCircularProgress color="#00FE79" size={30} />
        </div>
      )}
      <IconPowered theme={theme} />
    </Container>
  );
};
